<template>
  <div class="HospitalManagement">
    <a-spin :spinning="spinning">
      <!--      选择省份城市查询-->
      <div class="searcherC">
        <div style="display: flex; align-items: center">
          <a-button style="margin-top: -68px">省份:</a-button>
          <a-button
            style="font-weight: bold; font-family: 微软雅黑; margin-top: -68px"
            size="small"
            @click="allChange"
            >全部</a-button
          >
          <div class="provinces">
            <div v-for="(item, index) in options" :key="item.code">
              <a-button
                style="margin: 5px; font-weight: bold; font-family: 微软雅黑"
                size="small"
                :class="{ liBackground: provincesStyle == index }"
                id="provincesBtn"
                @click="provincesChange(item.name, index)"
                >{{ item.name }}</a-button
              >
            </div>
          </div>
        </div>
        <a-divider :dashed="true" />
        <div style="display: flex; align-items: center">
          <a-button>城市:</a-button> &nbsp;
          <a-button
            style="font-weight: bold; font-family: 微软雅黑"
            size="small"
            @click="allCity"
            >全部</a-button
          >

          <div class="City">
            <span v-for="(item, index) in City.cityList" :key="item.value">
              <a-button
                style="margin: 5px; font-weight: bold; font-family: 微软雅黑"
                size="small"
                :class="{ liBackground: CityStyle == index }"
                id="cityBtn"
                @click="CityChange(item.name, index)"
                >{{ item.name }}</a-button
              >
            </span>
          </div>
        </div>
      </div>
      <!--      搜索医院-->
      <div class="Searcher">
        <div class="searchH">
          名称 &nbsp;
          <a-input
            v-model="searchValue.name"
            placeholder="输入名称"
            style="width: 210px"
            size="default"
          />
        </div>
        <div class="searchH">
          等级 &nbsp;
          <a-tree-select
            v-model="searchValue.grade"
            style="width: 150px"
            :dropdown-style="{ maxHeight: '200px', overflow: 'auto' }"
            placeholder="选择等级"
            allow-clear
            tree-default-expand-all
          >
            <a-tree-select-node key="3" value="三级" title="三级">
              <a-tree-select-node key="3-1" value="三级甲等" title="三级甲等" />
              <a-tree-select-node key="3-2" value="三级乙等" title="三级乙等" />
              <a-tree-select-node key="3-3" value="三级丙等" title="三级丙等" />
            </a-tree-select-node>
            <a-tree-select-node key="2" value="二级" title="二级">
              <a-tree-select-node key="2-1" value="二级甲等" title="二级甲等" />
              <a-tree-select-node key="2-2" value="二级乙等" title="二级乙等" />
              <a-tree-select-node key="2-3" value="二级丙等" title="二级丙等" />
            </a-tree-select-node>
            <a-tree-select-node key="1" value="一级" title="一级">
              <a-tree-select-node key="1-1" value="一级甲等" title="一级甲等" />
              <a-tree-select-node key="1-2" value="一级乙等" title="一级乙等" />
              <a-tree-select-node key="1-3" value="一级丙等" title="一级丙等" />
            </a-tree-select-node>
          </a-tree-select>
        </div>
        <div class="searchH">
          是否开通质控 &nbsp;<a-select
            v-model="searchValue.control"
            style="width: 120px"
            placeholder="全部"
            @change="searchChange"
          >
            <a-select-option value="1"> 是 </a-select-option>
            <a-select-option value="0"> 否 </a-select-option>
          </a-select>
        </div>
        <div style="padding-left: 15px">
          <a-button @click="onSearch">查询</a-button>
          <a-button style="margin-left: 10px" @click="searchCancel"
            >重置</a-button
          >
        </div>
      </div>
      <div class="hospital_list">
        <a-button
          type="primary"
          @click="
            () => {
              this.visible = true;
              this.GetSelectDWXZ()
              this.onCreated();
            }
          "
          style="margin-bottom: 10px"
        >
          新增
        </a-button>
        <a-table
          :columns="columns"
          :data-source="HospitalList"
          :rowKey="(record) => record.id"
          :pagination="false"
        >
          <span slot="condition" slot-scope="text, record">
            <a-switch
              :checked="record.isSdicuzk === 1 ? true : false"
              @click="switchFT(record.id, record.isSdicuzk === 1 ? 0 : 1)"
              checked-children="是"
              un-checked-children="否"
            />
          </span>
          <span slot="conditionXXH" slot-scope="text, record">
            <a-switch
                :checked="record.isDeploySys === 1 ? true : false"
                @click="switchXXH(record.id, record.isDeploySys === 1 ? 0 : 1)"
                checked-children="是"
                un-checked-children="否"
            />
          </span>
          <span slot="city" slot-scope="text, record">
            {{ record.province }}/{{ record.city }}
          </span>
          <span slot="operate" slot-scope="text, record">
            <a style="color: #1890ff" @click="Edit(record)">编辑</a>&nbsp;
            <a-popconfirm
              cancel-text="否"
              ok-text="是"
              title="是否删除这条数据?"
              @confirm="delId(record.id)"
            >
            <a style="color: #1890ff">删除</a>
            </a-popconfirm>

          </span>
          <span slot="department" slot-scope="text, record">
            <a style="color: #1890ff" @click="departMentmanage(record)">管理</a>&nbsp;
          </span> 
        </a-table>
        <div
          style="
            display: flex;
            justify-content: flex-end;
            right: 35px;
            margin-top: 15px;
            margin-bottom: -10px;
          "
        >
          <a-pagination
            v-model="pagination.current"
            :total="pagination.total"
            @change="HospPagination"
          />
        </div>
      </div>

      <!------------------新增弹框----------->
      <a-drawer
        title="新增"
        :width="660"
        :visible="visible"
        :body-style="{ paddingBottom: '80px',paddingTop: '24px' }"
        @close="closeDraw()"
      >
        <a-form-model
          :rules="rules"
          :model="addForm"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          ref="addItem"
          hideRequiredMark
        >
          <a-form-model-item label="名称" prop="name">
            <a-input placeholder="输入名称" v-model="addForm.name" />
          </a-form-model-item>
          <a-form-model-item label="省份" prop="province">
            <a-select
              placeholder="选择省份"
              v-model="addForm.province"
              style="width: 120px"
            >
              <a-select-option
                v-for="(item, index) in options"
                :key="item.code"
                :value="item.name"
                @click="provinceAdd(item.name, index)"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="城市" prop="city">
            <a-select
              placeholder="选择城市"
              v-model="addForm.city"
              style="width: 120px"
            >
              <a-select-option
                v-for="item in CityNUM.cityList"
                :key="item.code"
                :value="item.name"
                @click="cityAdd(item.name)"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="等级" prop="level">
            <a-tree-select
              v-model="addForm.level"
              style="width: 100%"
              :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
              placeholder="选择等级"
              allow-clear
              tree-default-expand-all
            >
              <a-tree-select-node key="3" value="三级" title="三级">
                <a-tree-select-node
                  key="3-1"
                  value="三级甲等"
                  title="三级甲等"
                />
                <a-tree-select-node
                  key="3-2"
                  value="三级乙等"
                  title="三级乙等"
                />
                <a-tree-select-node
                  key="3-3"
                  value="三级丙等"
                  title="三级丙等"
                />
              </a-tree-select-node>
              <a-tree-select-node key="2" value="二级" title="二级">
                <a-tree-select-node
                  key="2-1"
                  value="二级甲等"
                  title="二级甲等"
                />
                <a-tree-select-node
                  key="2-2"
                  value="二级乙等"
                  title="二级乙等"
                />
                <a-tree-select-node
                  key="2-3"
                  value="二级丙等"
                  title="二级丙等"
                />
              </a-tree-select-node>
              <a-tree-select-node key="1" value="一级" title="一级">
                <a-tree-select-node
                  key="1-1"
                  value="一级甲等"
                  title="一级甲等"
                />
                <a-tree-select-node
                  key="1-2"
                  value="一级乙等"
                  title="一级乙等"
                />
                <a-tree-select-node
                  key="1-3"
                  value="一级丙等"
                  title="一级丙等"
                />
              </a-tree-select-node>
            </a-tree-select>
          </a-form-model-item>
          <a-form-model-item label="单位性质" prop="unitNature">
            <a-select
                  v-model="addForm.unitNature"
                  placeholder="请选择"
                  show-search
              >
                <a-select-option
                  v-for="item in stayList"
                  :value="item.name"
                  :key="item.id"
                  >
                  {{item.name}}
                </a-select-option>
              </a-select>
          </a-form-model-item>
          <a-form-model-item label="开通质控 " prop="switch">
            <a-switch
              @change="switchChange"
              checked-children="是"
              un-checked-children="否"
              default-checked
              v-model="addForm.isSdicuzk  == 1?true:false" 
            />
            <!-- <a-switch
                 @change="onNewBuildChange(addform.isLogin === 1? 0 : 1)"
                 v-model="addform.isLogin === 1?true:false" 
                 checked-children="是"
                 un-checked-children="否"
                 default-checked
                  /> -->
          </a-form-model-item>
          <a-form-model-item label="LOGO" prop="logo">
            <div
              style="
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                width: 90%;
              "
            >
              <div class="uploadImg">
                <input
                  style="
                    position: relative;
                    cursor: pointer;
                    width: 150px;
                    height: 150px;
                    opacity: 0;
                  "
                  accept="image/*"
                  type="file"
                  @change="uploadIcon"
                  ref="icon"
                  id="materialLibrary"
                />
              </div>
              <div class="upload_image1">
                <img
                  alt=""
                  v-if="addForm.logo"
                  style="width: 150px; height: 150px;position: absolute;top:0;left:0;"
                  :src="addForm.logo"
                />
              </div>
            </div>
          </a-form-model-item>
          <a-form-model-item label="简介" prop="introduce">
            <div v-if="visible == true" 
              style="border: 1px solid #ccc; min-height: 300px; width: 500px"
            >
              <Toolbar
                style="border-bottom: 1px solid #ccc"
                :editor="editor"
                :defaultConfig="toolbarConfig"
                :mode="mode"
              />
              <Editor
                style="height: 300px;;overflow-y: hidden"
                v-model="addForm.introduce"
                :defaultConfig="editorConfig"
                :mode="mode"
                @onCreated="onCreated"
              /> 
            </div>
          </a-form-model-item>
        </a-form-model>
        <div
          :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
        >
          <a-button
            :style="{ marginRight: '8px' }"
            @click="
              () => {
                visible = false;
                this.spinning = false;
                this.addForm.isSdicuzk = 0 
                this.$refs.addItem.resetFields();
              }
            "
          >
            取消
          </a-button>
          <a-button type="primary" @click="addOk"> 确认 </a-button>
        </div>
      </a-drawer>

      <!-- 科室管理  新增/编辑 -->
      <a-drawer
        title="科室管理/编辑"
        :width="1200"
        :visible="manageVisible"
        :body-style="{ paddingBottom: '80px' }"
        @close="
          () => {
            this.$refs.manageItem.resetFields();
            manageVisible = false;
            this.spinning = false;
            this.btnStatus = '新建'
          }
        "
      >
      <div style="display: flex;height: 100%;">
        <div style="width: 50%;border-right: 1px solid #ccc;padding-top:24px">
          <a-form-model
          :rules="manageRules"
          :model="manageForm"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          ref="manageItem"
        >
          <a-form-model-item label="名称" prop="name">
            <a-input placeholder="输入名称" v-model="manageForm.name" />
          </a-form-model-item>
          <a-form-model-item label="科室图片" prop="logo">
            <div
              style="
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                width: 100%;
              "
            >
              <div class="uploadImg2">
                <input
                  style="
                    position: relative;
                    cursor: pointer;
                    width: 330px;
                    height: 190px;
                    opacity: 0;
                  "
                  accept="image/*"
                  type="file"
                  @change="manageUploadIcon"
                  ref="manageIcon"
                  id="manageLibrary"
                />
              </div>
              <div class="upload_image2">
                <img
                  alt=""
                  v-if="manageForm.logo"
                  style="width: 330px; height: 190px;position: absolute;top:0;left:0;"
                  :src="manageForm.logo"
                />
              </div>
            </div>
          </a-form-model-item>
          <a-form-model-item label="科室简介" prop="introduce">
            <!-- <a-input placeholder="输入名称" v-model="manageForm.name" /> -->
            <div v-if="this.editorStatus == true" style="border: 1px solid #ccc;min-height: 301px;width: 450px">
              <Toolbar
                  style="border-bottom: 1px solid #ccc"
                   :editor="manageEditor"
                  :defaultConfig="toolbarConfig"
                  :mode="mode"
              />
              <Editor
                  style="height: 280px;;overflow-y: hidden"
                  v-model="manageForm.introduce"
                  :defaultConfig="editorConfig"
                  :mode="mode"
                  @onCreated="onManageCreated"
             />
            </div>
          </a-form-model-item>
          </a-form-model>
          <div style="width:100%;display:flex;justify-content:center">
            <a-button v-if="this.btnStatus == '新建'" type="primary" @click="saveOk"  style="width:100px;height: 40px;"> 新建科室 </a-button>
            <a-button v-if="this.btnStatus == '编辑'" type="primary" @click="editOOk" style="width:100px;height: 40px;"> 保存 </a-button>
          </div>
        <!-- <div
          :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
        >
          
        </div> -->
        </div>
        <div style="width: 50%;padding-left:20px;padding-top:24px">
        <a-table
          :columns="manageColumns"
          :data-source="manageList"
          :rowKey="(record) => record.id"
          :pagination="false"
        >
          <span slot="operate" slot-scope="text, record">
            <a style="color: #1890ff" @click="manageEdit(record)">编辑</a>&nbsp;
            <a-popconfirm
              cancel-text="否"
              ok-text="是"
              title="是否删除这条数据?"
              @confirm="managedelId(record.id)"
            >
              <a style="color: #1890ff">删除</a>
            </a-popconfirm>
          </span>
        </a-table>
        </div>
      </div>
      </a-drawer>

      <!--      修改医院信息-->
      <a-drawer
        title="编辑"
        :width="660"
        :visible="edit"
        :body-style="{ paddingBottom: '80px',paddingTop: '24px' }"
        @close="
          () => {
            edit = false;
            this.spinning = false;
          }
        "
      >
        <a-form-model
          :rules="rules"
          :model="editForm"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          ref="editItem"
          hideRequiredMark
        >
          <a-form-model-item label="名称" prop="name">
            <a-input placeholder="输入名称" v-model="editForm.name" />
          </a-form-model-item>
          <a-form-model-item label="省份" prop="province">
            <a-select
              placeholder="选择省份"
              v-model="editForm.province"
              style="width: 120px"
            >
              <a-select-option
                v-for="(item, index) in options"
                :key="item.code"
                :value="item.name"
                @click="provinceEdit(item.name, index)"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="城市" prop="city">
            <a-select
              placeholder="选择城市"
              v-model="editForm.city"
              style="width: 120px"
            >
              <a-select-option
                v-for="item in CityNUM.cityList"
                :key="item.code"
                :value="item.name"
                @click="cityEdit(item.name)"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="等级" prop="level">
            <a-tree-select
              v-model="editForm.level"
              style="width: 100%"
              :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
              placeholder="选择等级"
              allow-clear
              tree-default-expand-all
            >
              <a-tree-select-node key="3" value="三级" title="三级">
                <a-tree-select-node
                  key="3-1"
                  value="三级甲等"
                  title="三级甲等"
                />
                <a-tree-select-node
                  key="3-2"
                  value="三级乙等"
                  title="三级乙等"
                />
                <a-tree-select-node
                  key="3-3"
                  value="三级丙等"
                  title="三级丙等"
                />
              </a-tree-select-node>
              <a-tree-select-node key="2" value="二级" title="二级">
                <a-tree-select-node
                  key="2-1"
                  value="二级甲等"
                  title="二级甲等"
                />
                <a-tree-select-node
                  key="2-2"
                  value="二级乙等"
                  title="二级乙等"
                />
                <a-tree-select-node
                  key="2-3"
                  value="二级丙等"
                  title="二级丙等"
                />
              </a-tree-select-node>
              <a-tree-select-node key="1" value="一级" title="一级">
                <a-tree-select-node
                  key="1-1"
                  value="一级甲等"
                  title="一级甲等"
                />
                <a-tree-select-node
                  key="1-2"
                  value="一级乙等"
                  title="一级乙等"
                />
                <a-tree-select-node
                  key="1-3"
                  value="一级丙等"
                  title="一级丙等"
                />
              </a-tree-select-node>
            </a-tree-select>
          </a-form-model-item>
          <a-form-model-item label="单位性质" prop="unitNature">
            <a-select
                  v-model="editForm.unitNature"
                  placeholder="请选择"
                  show-search
              >
                <a-select-option
                  v-for="item in stayList"
                  :value="item.name"
                  :key="item.id"
                  >
                  {{item.name}}
                </a-select-option>
              </a-select>
          </a-form-model-item>
          <a-form-model-item label="开通质控">
            <a-switch
              :checked="editForm.isSdicuzk == 0 ? false : true"
              @click="switchEdit(editForm.isSdicuzk == 1 ? 0 : 1)"
              checked-children="是"
              un-checked-children="否"
            />
          </a-form-model-item>
          <a-form-model-item label="LOGO">
            <div
              style="
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                width: 90%;
              "
            >
              <div class="uploadImg">
                <input
                  style="
                    position: relative;
                    cursor: pointer;
                    width: 150px;
                    height: 150px;
                    opacity: 0;
                  "
                  accept="image/*"
                  type="file"
                  @change="editUploadIcon"
                  ref="editIcon"
                  id="editLibrary"
                />
              </div>
              <div class="upload_image1">
                <img
                  alt=""
                  v-if="editForm.logo"
                  style="width: 150px; height: 150px;position: absolute;top:0;left:0;"
                  :src="editForm.logo"
                />
              </div>
            </div>
          </a-form-model-item>
          <a-form-model-item label="简介" prop="introduce">
            <div v-if="edit == true" 
              style="border: 1px solid #ccc; min-height: 300px; width: 500px"
            >
              <Toolbar
                style="border-bottom: 1px solid #ccc"
                :editor="editEditor"
                :defaultConfig="toolbarConfig"
                :mode="mode"
              />
              <Editor
                style="height: 300px;;overflow-y: hidden"
                v-model="editForm.introduce"
                :defaultConfig="editorConfig"
                :mode="mode"
                @onCreated="onEditCreated"
              /> 
            </div>
          </a-form-model-item>
        </a-form-model>
        <div
          :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
        >
          <a-button
            :style="{ marginRight: '8px' }"
            @click="
              () => {
                edit = false;
                this.spinning = false;
              }
            "
          >
            取消
          </a-button>
          <a-button type="primary" @click="editOk"> 确认 </a-button>
        </div>
      </a-drawer>
    </a-spin>
  </div>
</template>

<script>
import City from "@/assets/city.json";
import { update } from "@/utils/update";
import E from 'wangeditor'
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import axios from "axios";
import TcVod from "vod-js-sdk-v6";
import { getSelect } from "@/service/Recruiting";
import {
  delHospital,
  getHospital,
  getHospitalId,
  postHospitalAdd,
  getHospitalDepartments,
  putHospitalEdit,
  postHospitalDepartments,
  putHospitalDepartments,
  delhospitalDepartments
} from "@/service/hospital_api";
const editorConfig = {
  // JS 语法
  MENU_CONF: {},
};
const signature = "";
// 修改 uploadImage 菜单配置
editorConfig.MENU_CONF["uploadImage"] = {
  // 自定义上传
  async customUpload(file, insertFn) {
    let formData = new FormData();
    formData.append("file", file);
    // file 即选中的文件,自己实现上传，并得到图片 url alt href
    axios
      .post(
        "https://yun-new-admin-api.yunicu.com/api/admin/common/update",
        formData
      )
      .then((res) => {
        if (res.data.code === 0) {
          insertFn(res.data.data.url); // 最后插入图片
        }
      });
  },
};
// 修改 uploadVideo 菜单配置
editorConfig.MENU_CONF["uploadVideo"] = {
  // 自定义上传
  async customUpload(file, insertFn) {
    let mediaFile = file;
    //获取签名
    axios
      .get("https://yun-new-admin-api.yunicu.com/v3/getUgcUploadSign")
      .then((res) => {
        if (res.data.code === 0) {
          const getSignature = () => {
            return res.data.data.signature;
          };
          const tcVod = new TcVod({
            getSignature: getSignature, //上传签名的函数
          });
          const uploader = tcVod.upload({
            mediaFile: mediaFile, // 媒体文件（视频或音频或图片），类型为 File
          });
          uploader.done().then((res) => {
            insertFn(res.video.url); // 进行视频处理：转码和截图
          });
        }
      });
  },
};
export default {
  name: "Hospital",
  components: { Editor, Toolbar },
  data() {
    return {
      sdicuzk: 0,
      spinning: false,
      isShow: 10,
      showMore: false,
      options: City,
      City: "",
      treeExpandedKeys: [],
      visible: false,
      manageVisible:false,
      edit: false,
      searchValue: {
        name: "",
        grade: undefined,
        control: undefined,
      },
      labelCol: { span: 3 },
      wrapperCol: { span: 14},
      addForm: {
        level: undefined,
        name: "",
        isSdicuzk: "0",
        city: undefined,
        province: undefined,
      },
      editForm: {
        city: "",
        province: "",
      },
      manageForm:{
        logo:"https://yun-new-admin-1252497858.cos.ap-guangzhou.myqcloud.com/images/5fc74d3a-3b83-4c23-8cf0-ccc1b76679c9.jpg"
      },
      mode: 'default',
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      columns: [
        {
          title: "序号",
          dataIndex: "sortIndex",
          width: "5%",
        },
        {
          title: "名称",
          dataIndex: "name",
          width: "12%",
        },
        {
          title: "省份/城市",
          scopedSlots: { customRender: "city" },
          width: "10%",
        },
        {
          title: "等级",
          dataIndex: "level",
          width: "8%",
        },
        {
          title: "科室",
          // dataIndex: "level",
          width: "8%",
          scopedSlots: { customRender: "department" },
        },
        {
          title: "开通质控",
          width: "8%",
          scopedSlots: { customRender: "condition" },
        },
        {
          title: "部署医院信息化",
          width: "8%",
          align: 'center',
          scopedSlots: { customRender: "conditionXXH" },
        },
        {
          title: "操作",
          width: "10%",
          scopedSlots: { customRender: "operate" },
        },
      ],
      manageColumns:[
      {
          title: "科室名称",
          dataIndex: "name",
        },
        {
          title: "创建人",
          dataIndex: "userName",
        },
        {
          title: "创建时间",  
          dataIndex: "createdTime",
        },
        {
          title: "操作",
          width: 150,
          scopedSlots: { customRender: "operate" },
        },
      ],
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        province: [
          { required: true, message: "请选择省份", trigger: "change" },
        ],
        city: [{ required: true, message: "请选择城市", trigger: "change" }],
        level: [{ required: true, message: "请选择等级", trigger: "change" }],
      },
      manageRules:{
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
      },
      HospitalList: [],
      manageList:[],
      CityNUM: "",
      CaseList: [],
      CityStyle: null,
      provincesStyle: null,
      provinceS: "",
      cityS: "",
      value: undefined,
      editLevel: undefined,
      editor: null,
      manageEditor:null,
      editEditor:null,
      toolbarConfig: {},
      editorConfig,
      manageId:"",
      btnStatus:"新建",
      editorStatus:true,
      switchStatus:false,
      stayList:[],
    };
  },
  created() {
    this.$store.dispatch("setManageHeaderTitle", "医院管理");
  },
  mounted() {
    this.getHospitalList();
  },
  methods: {
    closeDraw(){
      this.visible = false;
      this.spinning = false;
      this.switchStatus = false
      this.addForm.isSdicuzk  = 0
      this.$refs.addItem.resetFields();
    },    // 科室管理
    async departMentmanage(record){
      this.manageId = record.id
      this.manageVisible = true;  
      this.obtainHospitalDepartments()
    },
    // 根据医院id获取科室
    async obtainHospitalDepartments(){
      const response = await getHospitalDepartments(this.manageId);
      if (response.code === 0) {
        this.manageList = response.data;
      } else {
        this.$message.error("获取错误，请联系管理员~" + response.message);
      }
    },
    // 科室编辑
    manageEdit(record){
      // this.editorStatus = false
      this.manageForm = record;//数据回显
      this.btnStatus = "编辑"
      // this.onManageCreated()
      // this.manageEditor.destroy()
      // this.editorStatus = true
    },
    // 科室删除
    async managedelId(id){
      const response = await delhospitalDepartments(id);
      if (response.code === 0) {
        this.$message.success("删除成功")
        this.obtainHospitalDepartments()
      } else {
        this.$message.error("获取错误，请联系管理员~" + response.message);
      }
    },
    // 科室保存
    saveOk(){
      this.manageForm.hospitalId = this.manageId
      this.$refs.manageItem.validate(async (valid) => {
        if (valid) {
          const response = await postHospitalDepartments(this.manageForm);
          this.editorStatus = false
          if (response.code === 0) {
            await this.obtainHospitalDepartments();
            this.$message.success("添加成功");
            this.$refs.manageItem.resetFields();
            this.editorStatus = true
          }
        } else {
          this.$message.error("输入有空~");
          return false;
        }
      });
    },
    editOOk(){
      this.$refs.manageItem.validate(async (valid) => {
        if (valid) {
          const response = await putHospitalDepartments(this.manageForm);
          if (response.code === 0) {
            this.editorStatus = false
            await this.obtainHospitalDepartments();
            this.$message.success("修改成功");
            this.btnStatus = "新建" 
            this.$refs.manageItem.resetFields();
            this.editorStatus = true
            // this.manageEditor.destroy()  
            // this.onManageCreated()
          }
        } else {
          this.$message.error("输入有空~");
          return false;
        }
      });
    },
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
    },
    onManageCreated(manageEditor){
      this.manageEditor = Object.seal(manageEditor);
    },
    onEditCreated(editEditor){
      this.editEditor = Object.seal(editEditor);
    },
    beforeDestroy() {
       const editor = this.editor
       const manageEditor = this.manageEditor
       const editEditor = this.editEditor

       if (editor == null) return
       if (manageEditor == null) return
       if (editEditor == null) return
       editor.destroy() // 组件销毁时，及时销毁编辑器
       manageEditor.destroy() // 组件销毁时，及时销毁编辑器
       editEditor.destroy() // 组件销毁时，及时销毁编辑器
     },
    // 获取医院列表
    async getHospitalList(data) {
      this.spinning = true;
      const result = await getHospital(data);
      if (result.code === 0) {
        this.HospitalList = result.data.rows;
        this.pagination.total = result.data.count;
      } else {
        this.$message.error("获取错误，请联系管理员~" + result.message);
      }
      this.spinning = false;
    },
    async switchFT(id, sdicuzk) {
      const data = {
        isSdicuzk: sdicuzk,
      };
      const switchS = await putHospitalEdit(id, data);
      if (switchS.code === 0) {
        this.$message.success("修改成功");
        this.getHospitalList(); 
      }
    },
    async switchXXH(id, sdicuzk) {
      const data = {
        isDeploySys: sdicuzk,
      }
      const switchS = await putHospitalEdit(id, data);
      if (switchS.code === 0) {
        this.$message.success("修改成功");
        await this.getHospitalList();
      }
    },
    // 分页
    async HospPagination() {
      this.spinning = true;
      const Form = this.searchValue;
      const data = {
        name: Form.name,
        level: Form.grade,
        sdicuzk: Form.control,
        pagenum: this.pagination.current,
        province: this.provinceS,
        city: this.cityS,
      };

      await this.getHospitalList(data);

      this.spinning = false;
    },
    // 质控开关
    switchChange(checked) {
      if (checked === false) {
        this.switchStatus = true
        this.addForm.isSdicuzk = "0";
      } else {
        this.switchStatus = false
        this.addForm.isSdicuzk = "1";
      }
    },
    //全部按钮
    allChange() {
      this.getHospitalList();
      this.provincesStyle = null;
      this.City = "";
    },
    allCity() {
      this.CityStyle = null;
      this.cityS = "";
      const data = {
        province: this.provinceS,
      };
      this.getHospitalList(data);
    },
    // 省份城市添加
    provinceAdd(value, index) {
      this.CityNUM = this.options[index];
      this.addForm.province = value;
      this.addForm.city = undefined;
    },
    cityAdd(e) {
      this.addForm.city = e;
      this.provinceS = "";
    },
    //省份城市修改
    provinceEdit(value, index) {
      this.CityNUM = this.options[index];
      this.editForm.province = value;
      this.editForm.city = undefined;
    },
    cityEdit(e) {
      this.editForm.city = e;
    },
    // 选择省份城市搜索
    async provincesChange(e, index) {
      this.provincesStyle = index;
      this.City = this.options[index];
      this.CityStyle = null;
      this.provinceS = e;
      this.cityS = undefined;
      this.pagination.current = 1;
      const data = {
        province: e,
      };
      await this.getHospitalList(data);
    },
    // 城市选择
    async CityChange(e, index) {
      this.CityStyle = index;
      this.cityS = e;
      const data = {
        province: this.provinceS,
        city: e,
      };
      await this.getHospitalList(data);
      this.pagination.current = 1;
    },
    // 新增确认提交
    async addOk() {
      this.$refs.addItem.validate(async (valid) => {
        if (valid) {
          const addFrom = await postHospitalAdd(this.addForm);
          if (addFrom.code === 0) {
            const Form = this.searchValue;
            // 添加成功后查询
            const data = {
              name: Form.name,
              level: Form.grade,
              sdicuzk: Form.control,
              pagenum: this.pagination.current,
            };
            await this.getHospitalList(data);
            this.$message.success("添加成功");
            this.visible = false;
            this.$refs.addItem.resetFields();
          }
        } else {
          this.$message.error("输入有空~");
          return false;
        }
      });
    },
    // 展开编辑框
    async Edit(ref) {
      this.edit = true;
      const id = ref.id;
      const editId = await getHospitalId(id);
      this.GetSelectDWXZ()
      if (editId.code === 0) {
        this.editForm = editId.data;
      } else {
        this.$message.error("获取接口失败" + editId.message);
      }
    },
    // 修改质控
    switchEdit(sdicuzk) {
      this.editForm.isSdicuzk = sdicuzk;
    },
    // 修改医院
    async editOk() {
      // console.log("修改表单",this.editForm)
      this.$refs.editItem.validate(async (valid) => {
        if (valid) {
          const id = this.editForm.id;
          const edit = await putHospitalEdit(id, this.editForm);
          if (edit.code === 0) {
            const Form = this.searchValue;
            const data = {
              name: Form.name,
              level: Form.grade,
              sdicuzk: Form.control,
              pagenum: this.pagination.current,
            };
            await this.getHospitalList(data);
            this.$refs.editItem.resetFields();
            this.$message.success("修改成功");
            this.edit = false;
          }
        } else {
          this.$message.error("输入有空~");
          return false;
        }
      });
    },
    // 删除
    async delId(id) {
      const dalId = await delHospital(id);
      if (dalId.code === 0) {
        this.$message.success("删除成功");
        const Form = this.searchValue;
        const data = {
          name: Form.name,
          level: Form.grade,
          sdicuzk: Form.control,
          pagenum: this.pagination.current,
        };
        await this.getHospitalList(data);
      }
    },
    // 医院搜索
    async onSearch() {
      const Form = this.searchValue;
      const data = {
        name: Form.name,
        level: Form.grade,
        sdicuzk: Form.control,
        province: this.provinceS,
        city: this.cityS,
      };
      await this.getHospitalList(data);
      this.pagination.current = 1;
    },
    // 质控选择
    searchChange(value) {
      this.onSearch();
    },
    // 选择重置
    searchCancel() {
      this.getHospitalList();
      this.searchValue = {};
      this.cityS = "";
      this.provinceS = "";
      this.CityStyle = null;
      this.provincesStyle = null;
    },
    // 新增图片上传
    async uploadIcon() {
      let inputDOM = this.$refs.icon.files[0];
      const image = await update(inputDOM);
      if (image.code === 0) {
        this.$set(this.addForm, "logo", image.data.url);
        this.$message.success("上传成功");
        document.getElementById("materialLibrary").value = null;
        // this.$refs.ruleForm_add.clearValidate("icon");
      } else {
        this.$message.warning("上传失败" + image.message);
      }
    },
    // 编辑图片上传
    async editUploadIcon() {
      let inputDOM = this.$refs.editIcon.files[0];
      const image = await update(inputDOM);
      if (image.code === 0) {
        this.$set(this.editForm, "logo", image.data.url);
        this.$message.success("上传成功");
        document.getElementById("editLibrary").value = null;
        // this.$refs.ruleForm_add.clearValidate("icon");
      } else {
        this.$message.warning("上传失败" + image.message);
      }
    },
    async manageUploadIcon() {
      let inputDOM = this.$refs.manageIcon.files[0];
      const image = await update(inputDOM);
      if (image.code === 0) {
        this.$set(this.manageForm, "logo", image.data.url);
        this.$message.success("上传成功");
        document.getElementById("manageLibrary").value = null;
        // this.$refs.manageItem.clearValidate("manageIcon");
      } else {
        this.$message.warning("上传失败" + image.message);
      }
    },
    // 单位性质下拉框
    async GetSelectDWXZ(){ 
      this.stayList = []  //置空不然一直叠加选项
      const response = await getSelect("DWXZ")
      if(response.code === 0){
         let list = response.data.value
         for (let i=0;i<list.length;i++){
           this.stayList.push({
             id: i,
             name: list[i],
           })
         } 
      }
      else{
        this.$message.warning(response.message)
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.liBackground {
  background: #1890ff !important;
  color: white;
}

.HospitalManagement {
  margin: -15px -15px;
  background-color: whitesmoke;
  .searcherC {
    padding: 15px;
    margin: 0 auto;
    background-color: white;
    height: auto;
    font-size: 15px;
    .ant-btn {
      border: none !important;
      box-shadow: none !important;
    }
    .provinces {
      margin-left: 20px;
      display: flex;
      flex-wrap: wrap;
    }
    .City {
      display: flex;
      flex-wrap: wrap;
      margin-left: 20px;
    }
  }
  .Searcher {
    padding: 5px;
    height: 120px;
    width: 100%;
    margin: 10px auto;
    background-color: white;
    .searchH {
      margin: 20px;
      display: inline-flex;
      align-items: center;
    }
  }
  .hospital_list {
    background-color: white;
    margin: 0 auto;
    padding: 15px;
  }
}
.uploadImg {
  width: 150px;
  height: 150px;
  border: #dddddd 2px dashed;
  background-image: url("../../../assets/add.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  // cursor: pointer;
  // 1.子绝父相  2.z-index提升层级
  input{
    z-index:2;width: 100%;height: 100%;opacity: 0;cursor: pointer;
  }
  .upload_image1 {
  // border: #dddddd 2px dashed;
  width: 150px;
  height: 150px;
  // display: table-cell;
  // vertical-align: middle;
  // text-align: center;
  // cursor: pointer;
  position: absolute;
  top: 0%;
  left: 0%;
  text-align: center;
  z-index: -1
}
}

.uploadImg2 {
  width: 330px;
  height: 190px;
  border: #dddddd 2px dashed;
  //background-image: url("https://yun-new-admin-1252497858.cos.ap-guangzhou.myqcloud.com/images/5fc74d3a-3b83-4c23-8cf0-ccc1b76679c9.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  // cursor: pointer;
  // 1.子绝父相  2.z-index提升层级
  input{
    z-index:2;width: 100%;height: 100%;opacity: 0;cursor: pointer;
  }
  .upload_image2 {
  // border: #dddddd 2px dashed;
  width: 200px;
  height: 100px;
  // display: table-cell;
  // vertical-align: middle;
  // text-align: center;
  // cursor: pointer;
  position: absolute;
  top: 0%;
  left: 0%;
  text-align: center;
  z-index: -1
}
}
.upload3Img2 {
  width: 330px;
  height: 190px;
  border: #dddddd 2px dashed;
  // background-image: url("https://yun-new-admin-1252497858.cos.ap-guangzhou.myqcloud.com/images/5fc74d3a-3b83-4c23-8cf0-ccc1b76679c9.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  // cursor: pointer;
  // 1.子绝父相  2.z-index提升层级
  input{
    z-index:2;width: 100%;height: 100%;opacity: 0;cursor: pointer;
  }
  .upload_image2 {
  // border: #dddddd 2px dashed;
  width: 200px;
  height: 100px;
  // display: table-cell;
  // vertical-align: middle;
  // text-align: center;
  // cursor: pointer;
  position: absolute;
  top: 0%;
  left: 0%;
  text-align: center;
  z-index: -1
}
}

.upload{
  position: relative;
  border: 1px #DDDDDD dashed;
  width: 100px;
  height: 100px;
  input{
    z-index:2;width: 100%;height: 100%;opacity: 0;cursor: pointer;
  }
  .upload_text{
    cursor: pointer;position: absolute;top: 40%;left: 35%;text-align: center;z-index: -1
  }
}

::v-deep .ant-drawer-body{
  padding-top:0
}
</style>
